
// const apiBaseUrl = 'https://viktrsapi.zusedigital.com'; //Live
//const apiBaseUrl = 'https://api.viktrs.com'; //Live MARK AWS
const apiBaseUrl = 'https://apistaging.viktrs.com'; //Live MARK AWS
// const apiBaseUrl = 'https://viktrsstagingapi.zusedigital.com'; // Staging
// const apiBaseUrl = 'http://localhost:5000'; // Localhost
const currency = "£";
// const frontUrl = "https://viktrs-bucket.s3.eu-west-2.amazonaws.com/";

// const frontUrl = "https://dmw5l5i9se9t4.cloudfront.net/";
const frontUrl = "https://d30wifw6rr8dg3.cloudfront.net/"; //Live MARK S3

const secretKey= "viktrs#$&()**@!78*/Vik!123secure@#$%*&789";


module.exports = {  
    apiBaseUrl,
    currency,
    frontUrl,
    secretKey
};
